import React from 'react'
import { Block, Content } from 'gerami'

import Layout from '../../../shared/components/layout/layout'
import SEO from '../../../shared/components/seo/seo'
import Anchor from '../../../shared/components/anchor/anchor'

type _404Props = {}

const _404: React.FC<_404Props> = ({}) => (
  <>
    <SEO title="Not found (404)" />

    <Layout>
      <Content
        transparent
        size="XL"
        className="center padding-vertical-very-big"
      >
        <Block first>
          <h1
            className="margin-top-very-big padding-top-very-big"
            style={{ fontSize: `5.6rem` }}
          >
            😕
          </h1>
          <h1 className="padding-none">404! Not found.</h1>
        </Block>
        <Block>
          <p className="margin-none padding-none">
            Seems like you just hit a route that doesn&#39;t exist.
          </p>
        </Block>
        <Block first last>
          <hr />
        </Block>
        <Block last>
          <Anchor
            className="gerami-button gerami-button-primary padding-vertical-big padding-horizontal-very-big"
            to="/"
          >
            Go home
          </Anchor>
        </Block>
      </Content>
    </Layout>
  </>
)

export default _404
